@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --radius: 7px;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

input {
  margin: initial;
  padding: initial;
  color: initial;
  background-color: initial;
  border: initial;
  outline: initial;
  box-shadow: initial;

  font-size: 1rem;
  padding: 5px;
  background: rgb(248, 248, 248);
  border: solid rgb(190, 190, 190) 1px;
  border-radius: var(--radius);

  &[type="number"] {
    -moz-appearance: textfield; // For Firefox

    &::-webkit-inner-spin-button, // For Webkit browsers like Chrome and Safari
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.App {
  width: 100%;
  height: 100svh;
  display: grid;
  place-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    input {
      width: 100%;
    }
  }
}
